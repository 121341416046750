import * as React from "react";
import Amanda from "./../images/team-Amanda.png";
import Ben from "./../images/team-Ben.png";
import Designhorf from "./../images/team-designhorf.png";
import Mahmoud from "./../images/team-Mahmoud.png";
import Brendons from "./../images/team-Brendons.png";
import Vinny from "./../images/team-Vinny.png";
import Mustafa from "./../images/team-Mustafa.png";
import Loic from "./../images/team-Loic.png";
import Brian from "./../images/team-Brian.png";
import Toby from "./../images/team-Toby.png";
import Raymond from "./../images/team-Raymond.png";
// import MissingProfile from "../images/team-default.png";

export const teamData = [
  {
    firstname: "Ben",
    surename: "Gervais",
    title: "Co-Founder & Tech Lead",
    category: "ceo",
    about: [
      "Passionate about helping people have a better experience using DeFi and creating tools that leverage Data, AI, Automation and Web3 integrations.",
      "Over 15 years building software and startups, previously at SelfKey as Head of R&D working on Decentralized Identity technologies.",
    ],
    img: <img className="teamMemberImage" src={Ben} alt="Ben" />,
    github: "https://github.com/altninja",
    linkedin: "https://www.linkedin.com/in/bennygervais/",
  },
  {
    firstname: "Amanda",
    surename: "Sapach",
    category: "operations",
    title: "Operations Manager",
    img: <img className="teamMemberImage" src={Amanda} alt="Amanda" />,
    description:
      "6+ years of combined experience in Administration, Office & Operations Management, Sales & Marketing Coordination, and working with Start-ups.",
    github: "",
    linkedin: "https://www.linkedin.com/in/amandasapach/",
  },
  {
    firstname: "Brendons",
    surename: "Karelis",
    category: "dev",
    title: "Full Stack Developer",
    img: <img className="teamMemberImage" src={Brendons} alt="Brendons" />,
    description:
      "Full Stack Web Developer working with numerous of the latest web development technologies, experienced in eCommerce field and passionate about crypto.",
    github: "",
    linkedin: "https://www.linkedin.com/in/bkarelis/",
  },
  {
    firstname: "Brian",
    surename: "Chan",
    title: "Data Scientist",
    img: <img className="teamMemberImage" src={Brian} alt="Brian" />,
    category: "dev",
    description: `I'm a math PhD specializing in data science and machine learning.`,
    github: "https://github.com/Brian-T-Chan",
    linkedin: "https://www.linkedin.com/in/brian-chan-365211208/",
  },
  {
    firstname: "Ferenc",
    surename: "Horvath",
    category: "operations",
    title: "Lead Designer",
    img: <img className="teamMemberImage" src={Designhorf} alt="designhorf" />,
    description:
      "7+ years experience in UI/UX design & front-end development, specializing in startups in crypto.",
    github: "https://github.com/designhorf",
    linkedin: "https://www.linkedin.com/in/designhorf/",
  },
  {
    firstname: "Toby",
    surename: "Cao",
    title: "Back End Developer",
    category: "dev",
    description: [
      "Back End Developer working with Python, C++ , SQL, AWS. Graduated from Computer Science Major.",
    ],
    img: <img className="teamMemberImage" src={Toby} alt="Toby" />,
    github: "https://github.com/HackonToby",
    linkedin: "https://www.linkedin.com/in/toby-cao/",
  },
  {
    firstname: "Mahmoud",
    surename: "Elsayed Miz",
    category: "dev",
    title: "Full Stack Developer",
    img: <img className="teamMemberImage" src={Mahmoud} alt="Mahmoud" />,
    description:
      "Full Stack developer with 2 years of experience , Working with javascript React, Redux, Nodejs, sql, Mongodb.",
    github: "https://github.com/mahmoudmiz",
    linkedin: "https://www.linkedin.com/in/mahmoud-miz-7b29b014b/",
  },
  {
    firstname: "Vinny",
    surename: "Le",
    category: "operations",
    title: "Head of Marketing & Product",
    img: <img className="teamMemberImage" src={Vinny} alt="Vinny" />,
    description:
      "15+ years in banking and fintech, has implemented major investment platforms to thousands of users all over the world, and raised $100m+ in assets.",
    github: "",
    linkedin: "https://www.linkedin.com/in/vinny-le/",
  },
  // {
  //   firstname: "Saber",
  //   surename: "Chabbi",
  //   title: "Full Stack Developer",
  //   category: "dev",
  //   img: <img className="teamMemberImage" src={Saber} alt="Saber" />,
  //   description:
  //     "Full Stack Developer with 7+ years of hands-on experience designing, developing, and implementing applications and solutions using a range of technologies and programming languages.",
  //   github: "",
  //   linkedin: "https://www.linkedin.com/in/saber-chabbi-009675121/",
  // },
  {
    firstname: "Mustafa",
    surename: "Arıkan",
    title: "Community Manager",
    category: "operations",
    img: <img className="teamMemberImage" src={Mustafa} alt="Mustafa" />,
    description:
      "Graduated from Management Information Systems. Still working on computer systems in the Turkish Coast Guard Command.",
    github: "",
    linkedin: "https://www.linkedin.com/in/mustafa-ar%C4%B1kan-6ab47320a/",
  },
  {
    firstname: "Loic",
    surename: "Kendall",
    title: "Business Development Consultant",
    category: "operations",
    img: <img className="teamMemberImage" src={Loic} alt="Loic" />,
    description:
      "Business development specialist with 8+ years’ experience identifying prospective partners, pitching services, and building successful relationships.",
    github: "",
    linkedin: "https://www.linkedin.com/in/loic-kendall/",
  },

  {
    firstname: "Wai Si",
    surename: "Yip",
    title: "Junior MERN Stack Developer",
    category: "dev",
    description: [
      "Full-Stack developer specialize in MERN framework, passionate about create quality user interface.",
    ],
    img: <img className="teamMemberImage" src={Raymond} alt="Raymond" />,
    github: "https://github.com/c9y7ip",
    linkedin: "https://www.linkedin.com/in/yws197/ ",
  },

  // {
  //     'firstname': 'KeyFi',
  //     'surename': 'Member',
  //     'title': '',
  //     'img': <img className="teamMemberImage" src={MissingProfile} alt='' />,
  //     'description': '',
  //     'github': '',
  //     'linkedin': ''
  // }
];
