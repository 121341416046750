import * as React from "react";
import styled, { ThemeProvider } from "styled-components";
import { breakPoints, lightTheme } from "../components/theme";
import MetaComponent from "../components/MetaComponent";
import HeroGeneral from "../components/heroGeneral";
import heroBackgound from "../images/team_newbg.jpg";
import heroBackgoundMobile from "../images/team_newbg_mobile.jpg";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import GithubIcon from "./../images/icons/github.svg";
import LinkedinIcon from "./../images/icons/linkedin.svg";
import { teamData } from "../data/teamData";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";

// import "./team.css";
import "./index.css";

const Wrapper = styled.div`
  background: white;
  overflow: hidden;
  position: relative;
`;

const TeamWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.25rem 3rem;
  .teamTitle {
    font-size: 32px;
    font-weight: 700;
    max-width: 825px;
    margin: 6rem auto 0;
    padding: 0 3rem;
    text-align: center;
    @media (max-width: ${breakPoints.lg}px) {
      padding: 0;
    }
  }
`;

const CeoWrapper = styled.div`
  margin: 4rem 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${breakPoints.lg}px) {
    flex-direction: column;
  }
  > img {
    height: 240px;
    width: 240px;
  }
  .ceoInfo {
    @media (max-width: ${breakPoints.lg}px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      .ceoTitle {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .ceoTitle {
      .teamMemberName {
        font-size: 24px;
        font-weight: 600;
        color: ${(props) => props.theme.mainDarkText};
        margin: 0;
        ::before {
          background-color: ${(props) => props.theme.lightBlue2};
          content: "";
          height: 30px;
          margin-left: -16px;
          position: absolute;
          width: 8px;
        }
      }
      .teamMemberTitle {
        font-size: 16px;
        color: ${(props) => props.theme.mainDarkText};
        opacity: 0.6;
        margin: 6px 0 1rem;
        font-weight: 500;
      }
      .teamMemberSocialIconsWrap {
        a {
          img {
            height: 24px;
            width: 24px;
            margin-right: 0.75rem;
          }
        }
      }
    }
    .ceoAbout {
      li {
        font-weight: 500;
        color: black;
        font-size: 18px;
        line-height: 32px;
      }
      @media (max-width: ${breakPoints.lg}px) {
        text-align: center;
      }
    }
  }
`;

const TeamContentWrapper = styled.div`
  display: grid;
  column-gap: 3.125rem;
  grid-template-columns: repeat(auto-fit, 310px);
  grid-template-rows: auto;
  justify-content: center;
  justify-items: center;
  padding: 1.5em 0;
  row-gap: 5em;
  .teamMemberWrap {
    > img {
      height: 240px;
      width: 240px;
    }
    .teamMemberInfo {
      margin-left: 2rem;

      .teamMemberNames {
        position: relative;
        h2 {
          margin: 0;
          font-size: 24px;
          font-weight: 500;
        }
        ::before {
          background-color: ${(props) => props.theme.lightBlue2};
          content: "";
          height: 100%;
          margin-left: -16px;
          position: absolute;
          width: 8px;
        }
      }
      .teamMemberTitle {
        margin: 0.25rem 0 0.75rem;
        color: ${(props) => props.theme.mainDarkText};
        opacity: 0.6;
        font-weight: 500;
        font-size: 16px;
      }
      .teamMemberSocialIconsWrap {
        a {
          img {
            height: 24px;
            width: 24px;
            margin-right: 0.75rem;
          }
        }
      }
      .teamMemberDescription {
        color: ${(props) => props.theme.mainDarkText};
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
      }
    }
  }
  @media (max-width: ${breakPoints.md}px) {
    row-gap: 2rem;
    .teamMemberWrap {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      .teamMemberInfo {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-left: 0;
        .teamMemberNames {
          display: flex;
          h2:first-of-type {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
`;

const TeamPage = () => {
  const {t} = useTranslation();
    return (
    <ThemeProvider theme={lightTheme}>
      <MetaComponent pageName="Team" />
      <Wrapper>
        <Menu activeMenu="team" />
        <HeroGeneral
          title={t("homepage.footer.team")}
          background={heroBackgound}
          backgoundmb={heroBackgoundMobile}
        />
        <TeamWrapper>
          {teamData.map(
            (teamMember, indx) =>
              teamMember.category === "ceo" && (
                <CeoWrapper>
                  {teamMember.img}
                  <div className="ceoInfo">
                    <div className="ceoTitle">
                      <h2 className="teamMemberName">
                        {teamMember.firstname} {teamMember.surename}
                      </h2>
                      <h3 className="teamMemberTitle">{teamMember.title}</h3>
                      <div className="teamMemberSocialIconsWrap">
                        {teamMember.github && (
                          <a
                            href={teamMember.github}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              className="teamMemberSocialIcon"
                              src={GithubIcon}
                              alt="github icon"
                            />
                          </a>
                        )}
                        {teamMember.linkedin && (
                          <a
                            href={teamMember.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              className="teamMemberSocialIcon"
                              src={LinkedinIcon}
                              alt="linkedin icon"
                            />
                          </a>
                        )}
                      </div>
                    </div>
                    <ul className="ceoAbout">
                      {teamMember.about.map((item) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  </div>
                </CeoWrapper>
              )
          )}

          <div className="teamTitle">{t("about.team.group1")}</div>
          <TeamContentWrapper>
            {teamData.map(
              (teamMember, indx) =>
                teamMember.category === "operations" && (
                  <div className="teamMemberWrap" key={indx}>
                    {teamMember.img}
                    <div className="teamMemberInfo">
                      <div className="teamMemberNames">
                        <h2 className="teamMemberName">
                          {teamMember.firstname}
                        </h2>
                        <h2 className="teamMemberName">
                          {teamMember.surename}
                        </h2>
                      </div>
                      <h3 className="teamMemberTitle">{teamMember.title}</h3>
                      <div className="teamMemberSocialIconsWrap">
                        {teamMember.github && (
                          <a
                            href={teamMember.github}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              className="teamMemberSocialIcon"
                              src={GithubIcon}
                              alt="github icon"
                            />
                          </a>
                        )}
                        {teamMember.linkedin && (
                          <a
                            href={teamMember.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              className="teamMemberSocialIcon"
                              src={LinkedinIcon}
                              alt="linkedin icon"
                            />
                          </a>
                        )}
                      </div>
                      <p className="teamMemberDescription">
                        {teamMember.description}
                      </p>
                    </div>
                  </div>
                )
            )}
          </TeamContentWrapper>
          <div className="teamTitle">{t("about.team.group2")}</div>
          <TeamContentWrapper>
            {teamData.map(
              (teamMember, indx) =>
                teamMember.category === "dev" && (
                  <div className="teamMemberWrap" key={indx}>
                    {teamMember.img}
                    <div className="teamMemberInfo">
                      <div className="teamMemberNames">
                        <h2 className="teamMemberName">
                          {teamMember.firstname}
                        </h2>
                        <h2 className="teamMemberName">
                          {teamMember.surename}
                        </h2>
                      </div>
                      <h3 className="teamMemberTitle">{teamMember.title}</h3>
                      <div className="teamMemberSocialIconsWrap">
                        {teamMember.github && (
                          <a
                            href={teamMember.github}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              className="teamMemberSocialIcon"
                              src={GithubIcon}
                              alt="github icon"
                            />
                          </a>
                        )}
                        {teamMember.linkedin && (
                          <a
                            href={teamMember.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              className="teamMemberSocialIcon"
                              src={LinkedinIcon}
                              alt="linkedin icon"
                            />
                          </a>
                        )}
                      </div>
                      <p className="teamMemberDescription">
                        {teamMember.description}
                      </p>
                    </div>
                  </div>
                )
            )}
          </TeamContentWrapper>
        </TeamWrapper>
        <Footer />
      </Wrapper>
    </ThemeProvider>
  );
};

export default TeamPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
